import React, {lazy, Suspense, useEffect} from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {Col, ConfigProvider, Divider, Layout, Menu, Row} from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import {BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {
    AppstoreOutlined,
    BulbOutlined,
    CarOutlined,
    DiffOutlined,
    EditOutlined, GatewayOutlined,
    LineChartOutlined,
    LoadingOutlined,
    SettingOutlined,
    SnippetsOutlined
} from '@ant-design/icons';
import cookie from 'react-cookies';
import '@/App.less';
import {Content, Footer, Header} from "antd/es/layout/layout";
import axios1 from "@/axios1";
// import axios from "@/axios";

const Gps = lazy(()=> import ('./Pages/Gps')) ;
const Home = lazy(() => import('./Pages/Home'));
const BillHome = lazy(() => import('./Pages/BillHome'));
const Work = lazy(() => import('./Pages/Work'));
const CarMap = lazy(() => import('./Pages/CarMap'));
const Detail = lazy(() => import('./Pages/Detail'));
const AutoWorkGeneratorHome = lazy(() => import('./Pages/AutoWorkGeneratorHome'));
const AutoWorkGenerator = lazy(() => import('./Pages/AutoWorkGenerator'));
const Bill = lazy(() => import('./Pages/Bill'));
const Contract = lazy(() => import('./Pages/Contract'));
const Login = lazy(() => import('./Pages/Auth/Login'));
const Logout = lazy(() => import('./Pages/Auth/Logout'));
const Car = lazy(() => import('./Pages/Car'));
const Insurance = lazy(() => import('./Pages/Insurance'));
const CarDetail = lazy(() => import('./Pages/CarDetail'));
const InsuranceDetail = lazy(() => import('./Pages/InsuranceDetail'));
const LoadInsuranceData = lazy(() => import('./Pages/LoadInsuranceData'));
const ParkinglotPage = lazy(() => import('./Pages/Parkinglot'));
const ChartPage = lazy(() => import('./Pages/ChartPage'));
const Driver = lazy(() => import('./Pages/SecondLayer/Driver'));
const LifeHome = lazy(() => import('./Pages/SecondLayer/LifeHome'));
const LifeLogin = lazy(() => import('./Pages/Auth1/Login'));
const LifeLogout = lazy(() => import('./Pages/Auth1/Logout'));
const DriverDetail = lazy(() => import('./Pages/SecondLayer/DriverDetail'));
const Transaction = lazy(() => import('./Pages/SecondLayer/Transaction'));
const Recharge = lazy(() => import('./Pages/SecondLayer/Recharge'));
const CarLock = lazy(() => import('./Pages/SecondLayer/CarLock'));
const CarService = lazy(() => import('./Pages/SecondLayer/CarService'));
const container = document.getElementById('root');
const root = createRoot(container);


const NavMenu1 = () => {
    let navigate = useNavigate()
    return (
        <Menu theme="dark" mode="horizontal" items={[
            {
                key: 'home',
                icon: <AppstoreOutlined/>,
                label: `主页`,
                onClick: () => navigate('/')
            },
            {
                key: 'bill',
                icon: <SnippetsOutlined/>,
                label: '账单',
                onClick: () => navigate('/bill/')
            }, {
                key: 'work',
                icon: <BulbOutlined/>,
                label: '工单',
                onClick: () => navigate('/work/')
            }, {
                key: 'auto-work-generator',
                icon: <EditOutlined/>,
                label: '工单自动生成系统',
                onClick: () => navigate('/auto-work-generator/')
            }, {
                key: 'car',
                icon: <CarOutlined/>,
                label: '车辆管理',
                onClick: () => navigate('/car/')
            }, {
                key: 'insurance',
                icon: <DiffOutlined/>,
                label: '保险管理',
                onClick: () => navigate('/insurance/')
            }, {
                key: 'parkinglot',
                icon: <DiffOutlined/>,
                label: '停车场报表',
                onClick: () => navigate('/parkinglot/')
            }, {
                key: 'charts',
                icon: <LineChartOutlined/>,
                label: '统计图表',
                onClick: () => navigate('/charts')
            },
            {
                key: 'gps',
                icon: <GatewayOutlined/>,
                label: '志远定位',
                onClick: () => navigate('/gps')
            }
        ]}/>
    )
}
const NavMenu2 = () => {
    let navigate = useNavigate()
    return (
        <Menu theme="dark" mode="horizontal" items={[
            {
                key: 'home',
                icon: <AppstoreOutlined/>,
                label: `主页`,
                onClick: () => navigate('/')
            }, {
                key: 'work',
                icon: <BulbOutlined/>,
                label: '工单',
                onClick: () => navigate('/work/')
            }
        ]}/>
    )
}
const NavMenu3 = () => {
    let navigate = useNavigate()
    return (
        <Menu theme="dark" mode="horizontal" items={[
            {
                key: 'load-insurance-data',
                icon: <BulbOutlined/>,
                label: '保险录入',
                onClick: () => navigate('/load-insurance-data/')
            }
        ]}/>
    )
}
const NavMenu4 = () => {
    let navigate = useNavigate()
    return (
        <Menu theme="dark" mode="horizontal" items={[
            {
                key: 'home',
                icon: <AppstoreOutlined/>,
                label: `主页`,
                onClick: () => navigate('/')
            }, {
                key: 'car',
                icon: <CarOutlined/>,
                label: '车辆管理',
                onClick: () => navigate('/car/')
            }
        ]}/>
    )
}
/**
 * 第二层菜单，所有角色调用
 * @returns {JSX.Element}
 * @constructor
 */
const NavMenu5 = () => {
    let navigate = useNavigate()
    return (
        // todo 通过第一层菜单的selectedKeys取消第一层的选中状态
        <Menu theme="dark" mode="horizontal" items={[
            {
                key: 'life-home',
                icon: <AppstoreOutlined/>,
                label: `首页`,
                onClick: () => navigate('/life-home')
            },
            {
                key: 'driver',
                icon: <AppstoreOutlined/>,
                label: `司机信息`,
                onClick: () => navigate('/driver')
            },

            {
                key: 'transaction',
                icon: <AppstoreOutlined/>,
                label: `交易记录`,
                onClick: () => navigate('/transaction')
            },
            {
                key: 'recharge',
                icon: <AppstoreOutlined/>,
                label: `充值记录`,
                onClick: () => navigate('/recharge')
            },
            {
                key: 'car-service',
                icon: <AppstoreOutlined/>,
                label: `车辆服务`,
                onClick: () => navigate('/car-service')
            },
            {
                key: 'car-lock',
                icon: <AppstoreOutlined/>,
                label: `锁车记录`,
                onClick: () => navigate('/car-lock')
            },

        ]}/>
    )
}
const UnLoggedInNavMenu = () => {
    return (
        <Menu theme="dark" mode="horizontal"/>
    )
}
const UserMenu = () => {
    let navigate = useNavigate()
    return (
        <Menu theme="dark" mode="horizontal" items={[
            {
                key: 'sub-menu',
                icon: <SettingOutlined/>,
                label: '我的',
                children: [
                    {
                        key: 'logout',
                        label: '登出',
                        onClick: () => navigate('/user-logout')
                    }
                ]
            }
        ]}>
        </Menu>
    )
}
const UserMenu1 = () => {
    let navigate = useNavigate()
    return (
        <Menu theme="dark" mode="horizontal" items={[
            {
                key: 'sub-menu',
                icon: <SettingOutlined/>,
                label: '我的',
                children: [
                    {
                        key: 'logout',
                        label: '登出',
                        onClick: () => navigate('/life-logout')
                    }
                ]
            }
        ]}>
        </Menu>
    )
}
const URL1 = () => {
    return (
        <Routes>
            <Route path={"/"} element={cookie.load('user') ? <Home/> : <Navigate to="/user-login"/>}/>
            <Route path={"/bill"} element={cookie.load('user') ? <BillHome/> : <Navigate to="/user-login"/>}/>
            <Route path={"/work"} element={cookie.load('user') ? <Work/> : <Navigate to="/user-login"/>}/>
            <Route path={"/auto-work-generator/:id"}
                   element={cookie.load('user') ? <AutoWorkGeneratorHome/> : <Navigate to="/user-login"/>}/>
            <Route path={"/auto-work-generator/"}
                   element={cookie.load('user') ? <AutoWorkGenerator/> : <Navigate to="/user-login"/>}/>
            <Route path={"/order-detail/:order_id"}
                   element={cookie.load('user') ? <Detail/> : <Navigate to="/user-login"/>}/>
            <Route path={"/car-map/:car_list"}
                   element={cookie.load('user') ? <CarMap/> : <Navigate to="/user-login"/>}/>
            <Route path="/bill/:order_id"
                   element={cookie.load('user') ? <Bill/> : <Navigate to="/user-login"/>}/>
            <Route path="/contract/:order_id"
                   element={cookie.load('user') ? <Contract/> : <Navigate to="/user-login"/>}/>
            <Route path="/car"
                   element={cookie.load('user') ? <Car/> : <Navigate to="/user-login"/>}/>
            <Route path="/parkinglot"
                   element={cookie.load('user') ? <ParkinglotPage/> : <Navigate to="/user-login"/>}/>
            <Route path="/charts"
                   element={cookie.load('user') ? <ChartPage/> : <Navigate to="/user-login"/>}/>
            <Route path="/insurance"
                   element={cookie.load('user') ? <Insurance/> : <Navigate to="/user-login"/>}/>
            <Route path="/insurance-detail/:insurance_id"
                   element={cookie.load('user') ? <InsuranceDetail/> : <Navigate to="/user-login"/>}/>
            <Route path="/load-insurance-data/"
                   element={cookie.load('user') ? <LoadInsuranceData/> : <Navigate to="/user-login"/>}/>
            <Route path={"/car-detail/:car_no"}
                   element={cookie.load('user') ? <CarDetail/> : <Navigate to="/user-login"/>}/>
            <Route path={"/gps"}
                   element={cookie.load('user') ? <Gps/> : <Navigate to="/user-login"/>}/>

            <Route path="/user-login" element={<Login/>}/>
            <Route path="/user-logout" element={<Logout/>}/>

            <Route path="/life-login" element={<LifeLogin/>}/>
            <Route path="/driver" element={<Driver/>}/>
            <Route path="/driver-detail/:driver_id" element={<DriverDetail/>}/>
            <Route path="/life-home" element={<LifeHome/>}/>
            <Route path="/life-logout" element={<LifeLogout/>}/>
            <Route path="/transaction" element={<Transaction/>}/>
            <Route path="/recharge" element={<Recharge/>}/>
            <Route path="/car-lock" element={<CarLock/>}/>
            <Route path="/car-service" element={<CarService/>}/>
        </Routes>
    )
}
const URL2 = () => {
    return (
        <Routes>
            <Route path={"/"} element={cookie.load('user') ? <Home/> : <Navigate to="/user-login"/>}/>
            <Route path={"/work"} element={cookie.load('user') ? <Work/> : <Navigate to="/user-login"/>}/>
            <Route path={"/order-detail/:order_id"}
                   element={cookie.load('user') ? <Detail/> : <Navigate to="/user-login"/>}/>
            <Route path="/bill/:order_id"
                   element={cookie.load('user') ? <Bill/> : <Navigate to="/user-login"/>}/>
            <Route path="/contract/:order_id"
                   element={cookie.load('user') ? <Contract/> : <Navigate to="/user-login"/>}/>
            <Route path="/user-login" element={<Login/>}/>
            <Route path="/user-logout" element={<Logout/>}/>
        </Routes>
    )
}
const URL3 = () => {
    return (
        <Routes>
            <Route path="/load-insurance-data/"
                   element={cookie.load('user') ? <LoadInsuranceData/> : <Navigate to="/user-login"/>}/>
            <Route path="/user-login" element={<Login/>}/>
            <Route path="/user-logout" element={<Logout/>}/>
        </Routes>
    )
}
const URL4 = () => {
    return (
        <Routes>
            <Route path="/car"
                   element={cookie.load('user') ? <Car/> : <Navigate to="/user-login"/>}/>
            <Route path={"/"} element={cookie.load('user') ? <Home/> : <Navigate to="/user-login"/>}/>
            <Route path="/user-login" element={<Login/>}/>
            <Route path="/user-logout" element={<Logout/>}/>
        </Routes>
    )
}
const get_navi_menu = () => {
    if (!cookie.load('user')) {
        return <UnLoggedInNavMenu/>
    }
    switch (true) {
        case cookie.load('user')['status'] === "资方":
            return <NavMenu4/>;
        case cookie.load('user')['status'] === "总经理" || cookie.load('user')['status'] === "财务":
            return <NavMenu1/>;
        case cookie.load('user')['status'] === "运营" || cookie.load('user')['status'] === "车管"
        || cookie.load('user')['status'] === "司管":
            return <NavMenu2/>;
        default:
            return <NavMenu3/>;
    }
}
// 第二行
const get_navi_menu_2 = () => {
    // todo 未登录时第二层菜单样式
    /*if(!cookie.load('user1')){
        return <UnLoggedInNavMenu />
    }*/
    if (!cookie.load('life_login')) {
        return <UnLoggedInNavMenu/>
    }
    switch (true) {
        case cookie.load('user')['status'] === "资方":
            return <NavMenu5/>;
        case cookie.load('user')['status'] === "总经理" || cookie.load('user')['status'] === "财务":
            return <NavMenu5/>;
        case cookie.load('user')['status'] === "运营" || cookie.load('user')['status'] === "车管"
        || cookie.load('user')['status'] === "司管":
            return <NavMenu5/>;
        default:
            return <NavMenu5/>;
    }
}
const get_url = () => {
    if (!cookie.load('user')) {
        return <URL1/>
    }
    switch (true) {
        case cookie.load('user')['status'] === "资方":
            return <URL4/>
        case cookie.load('user')['status'] === "总经理" || cookie.load('user')['status'] === "财务":
            return <URL1/>;
        case cookie.load('user')['status'] === "运营" || cookie.load('user')['status'] === "车管"
        || cookie.load('user')['status'] === "司管":
            return <URL2/>;
        default:
            return <URL3/>;
    }
}


// 切换菜单，0是绿贝出行，1是绿贝生活
const SelectedHeader = () => {
    const [header, setHeader] = React.useState(0) // 0是绿贝出行，1是绿贝生活
    const [accessToken, setAccessToken] = React.useState(''); // 新增状态来存储访问令牌
    const location = useLocation();
    useEffect(() => {
        // 根据当前路由，切换头部菜单
        const pathname = location.pathname;
        console.log('pathname:', pathname)
        const lifeRoutes = ['life', 'driver', 'transaction', 'recharge', 'car-lock', 'car-service'];
        // 如果当前页面是上面其中一个，导航栏为绿贝生活样式
        if (lifeRoutes.some(route => pathname.includes(route))) {
            setHeader(1)
        } else {
            setHeader(0)
        }
    }, [location.pathname]);

    // 新增 useEffect 来处理登录请求
    useEffect(() => {
        const fetchAccessToken = async () => {
            console.log(222)
            // const response = await axios.get("/auth/login");
            // console.log(response.data);
            // setAccessToken(response.data.data.access_token); // 更新访问令牌状态
            setAccessToken("7ea5943b-8389-4ee6-902d-c98008550eba"); // 更新访问令牌状态
        };
        fetchAccessToken();
    }, [location.pathname]); // 空依赖数组表示这个 effect 只在组件挂载时运行一次
    console.log('header:', header)
    if (header === 0) {
        return (
            <Header className="header" color={"light"}>
                <Row>
                    <Col flex="40px" order={1}>
                        <img src="/logo.png" alt={"logo"} style={{height: 38, width: "auto"}}/>
                    </Col>
                    <Col flex="auto" order={2}>
                        {get_navi_menu()}
                    </Col>
                    <Col flex="40px" order={3}>
                        {cookie.load('user') ? <UserMenu/> : ""}
                    </Col>
                </Row>
            </Header>
        )
    } else {
        return (
            <Header className="secondHeader" color={"light"}>
                <Row>
                    <Col flex="40px" order={1}>
                        <img src="/logo.png" alt={"logo"} style={{height: 38, width: "auto"}}/>
                    </Col>
                    <Col flex="auto" order={1}>
                        {get_navi_menu_2()}
                    </Col>
                    <Col flex="40px" order={3}>
                        {cookie.load('life_login') ? <UserMenu1/> : ""}
                    </Col>
                </Row>
            </Header>
        )
    }
}

root.render(
    <ConfigProvider locale={zhCN}>
        <Suspense fallback={
            <div style={{
                textAlign: "center",
                width: document.body.clientWidth,
                height: document.body.clientHeight * 0.45
            }}>
                <h1 style={{color: "#000080", fontFamily: "alibaba"}}>
                    正在加载
                </h1>
                <Divider/>
                <LoadingOutlined style={{
                    textAlign: "center", verticalAlign: "center",
                    fontSize: "100px",
                    marginTop: "30%",
                    color: "skyblue",
                }}/>
            </div>}>
            <Layout className="layout">
                <ConfigProvider locale={zhCN}>
                    <BrowserRouter>

                        <SelectedHeader/>

                        <Content style={{padding: '20px 50px 0 50px'}}>
                            {get_url()}
                        </Content>
                    </BrowserRouter>

                </ConfigProvider>
                <Footer style={{textAlign: 'center'}}>
                    <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">蜀ICP备2022000200号-1</a>
                    <span>&emsp;&emsp;&emsp;©2022 绿贝出行</span>
                </Footer>
            </Layout>
        </Suspense>
    </ConfigProvider>
);
reportWebVitals();
